<template>
	<w-layout justify-end row>
		<AdvancedSettings />
	</w-layout>
</template>

<script>
export default {
	name: 'NotificationsHeader',
	components: {
		AdvancedSettings: () => ({
			component: import('@/components/Notifications/Profile/AdvancedSettings')
		})
	}
}
</script>
